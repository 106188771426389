import React from "react"
import { ThemeProvider } from "@material-ui/styles"
import { IntlProvider, addLocaleData } from "react-intl"
import { CssBaseline, NoSsr, Hidden } from "@material-ui/core"
import PageTransition from "gatsby-v2-plugin-page-transitions"
import { graphql } from "gatsby"
import "../global_styles/global.css"

import {
  Header,
  HeroBanner,
  AvcnStndsMarketingCta,
  Footer,
  CartProvider,
  ProductLine,
  SEO,
  Grid,
  MobileOrientation,
} from "../../../components"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Theme from "../theme"
import CtaParser from "../../../utilities/marketingCtaParser"
import marketingCtaParser from "../../../contentModels/marketingCta"

import LeafLeft from "../images/completeLeafLeft.png"
import LeafRight from "../images/completeLeafRight.png"

import locale_es from "react-intl/locale-data/es"
import locale_en from "react-intl/locale-data/en"

addLocaleData([...locale_en, ...locale_es])

const IndexPage = ({ data, pageContext, location }) => {
  const langKey = pageContext.locale
  const i18nmessages = require(`../messages/index/${langKey}`)
  const heroBannerColor = data.content.edges[0].node.heroBanner.herBannerColor
  const heroBannerMediaPosition =
    data.content.edges[0].node.heroBanner.mediaPosition
  const slides = data.content.edges[0].node.heroBanner.slides
  const slideData = []
  const queryString = require("query-string")
  const parsedQuery = queryString.parse(location.search)

  for (let slide of slides) {
    let slideObject = {
      titleText: slide.richTitle,
      copyText: slide.richDescription,
      imageSrc: slide.image.fluid,
      videoSrc: slide.image.file.url,
      staticImg: slide.image.file.url,
      mediaType: slide.mediaType,
      mediaPosition: heroBannerMediaPosition,
      color: heroBannerColor,
    }
    slideData.push(slideObject)
  }
  const marketingCTAs = data.content.edges[0].node.marketingCtAs
  const marketingCtaData = []

  for (let mCta of marketingCTAs) {
    let mCtaObject = {
      richTitle: mCta.richTitle,
      richDescription: mCta.richDescription,
      picture: mCta.picture,
      mediaVimeoUrl: mCta.mediaVimeoUrl,
      mediaType: mCta.mediaType,
      marketingCtaType: mCta.marketingCtaType,
      orderNumber: mCta.orderNumber,
      contentPosition: mCta.contentPosition,
      buttonText: mCta.buttonText,
      buttonColor: mCta.buttonColor,
      redirectLink: `${langKey}/${mCta.redirectLink}`,
      color: mCta.backgroundColor,
      mediaPosition: mCta.mediaPosition,
      hideBackground:
        mCta.backgroundImage !== null && mCta.backgroundImage ? false : true,
    }
    marketingCtaData.push(mCtaObject)
  }

  const productLineData = {
    titleText: data.content.edges[0].node.productLines.titleText,
    items: [
      {
        id: data.content.edges[0].node.productLines.productOne.id,
        title:
          data.content.edges[0].node.productLines.productOne.productLineTitle,
        imageSrc:
          data.content.edges[0].node.productLines.productOne.image.fixed,
        buttonText:
          data.content.edges[0].node.productLines.productOne.cardButtonText,
        color: data.content.edges[0].node.productLines.productOne.color,
        slug: data.content.edges[0].node.productLines.productOne.slug,
        locale: data.content.edges[0].node.productLines.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
      {
        id: data.content.edges[0].node.productLines.productTwo.id,
        title:
          data.content.edges[0].node.productLines.productTwo.productLineTitle,
        imageSrc:
          data.content.edges[0].node.productLines.productTwo.image.fixed,
        buttonText:
          data.content.edges[0].node.productLines.productTwo.cardButtonText,
        color: data.content.edges[0].node.productLines.productTwo.color,
        slug: data.content.edges[0].node.productLines.productTwo.slug,
        locale: data.content.edges[0].node.productLines.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
      {
        id: data.content.edges[0].node.productLines.productThree.id,
        title:
          data.content.edges[0].node.productLines.productThree.productLineTitle,
        imageSrc:
          data.content.edges[0].node.productLines.productThree.image.fixed,
        buttonText:
          data.content.edges[0].node.productLines.productThree.cardButtonText,
        color: data.content.edges[0].node.productLines.productThree.color,
        slug: data.content.edges[0].node.productLines.productThree.slug,
        locale: data.content.edges[0].node.productLines.node_locale,
        isProductLine: true,
        langKey: langKey,
      },
    ],
  }

  //provides style for hr line
  const divider = {
    borderTop: "0.5px solid #C3C5C8",
  }

  const pageMetadata = {
    title: data.content.edges[0].node.metadataTitle,
    description:
      data.content.edges[0].node.metadataDescription.internal.content,
    url: location.href,
  }
  const {
    transitionTime,
    defaultTransitionTime,
  } = require("../../../constants/randomConsts")

  return (
    <PageTransition
      transitionTime={
        parsedQuery.transition ? transitionTime : defaultTransitionTime
      }
    >
      <IntlProvider locale={langKey} messages={i18nmessages}>
        <React.Fragment>
          <CssBaseline />
          <ThemeProvider theme={Theme}>
            <MobileOrientation />
            <SEO siteMetadata={pageMetadata} />
            <CartProvider cartId={undefined}>
              <Header
                data={data.header.edges[0].node}
                slug={pageContext.slug}
              />
              <HeroBanner
                isProductLine={false}
                static={true}
                slideData={slideData}
              />
              <CtaParser data={marketingCtaData} />
              <Hidden mdDown>
                <Grid container centered="true">
                  <Grid xs={8} lg={12} item centered="true">
                    <hr style={divider} />
                  </Grid>
                </Grid>
              </Hidden>
              <ProductLine
                productLineData={productLineData}
                langKey={langKey}
              />
              <Footer
                data={data.footer.edges[0].node}
                langKey={langKey}
                bgImgLeft={LeafLeft}
                bgImgRight={LeafRight}
              />
            </CartProvider>
          </ThemeProvider>
        </React.Fragment>
      </IntlProvider>
    </PageTransition>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query($locale: String!) {
    content: allContentfulAvicannaStandardsOfQualityPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          heroBanner {
            mediaPosition
            heroBannerColor
            slides {
              mediaType
              image {
                file {
                  url
                }
                fluid {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              richDescription {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              richTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
            }
          }
          marketingCtAs {
            backgroundColor
            backgroundImage
            richDescription {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            richTitle {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            marketingCtaType
            orderNumber
            buttonColor
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            redirectLink
            mediaPosition
            contentPosition
            mediaType
            mediaCaption
            mediaVimeoUrl
            picture {
              file {
                url
              }
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          title
          productLines {
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            node_locale
            productOne {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
            productThree {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
            productTwo {
              productLineTitle {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              image {
                fixed(width: 320) {
                  ...GatsbyContentfulFixed_withWebp
                }
              }
              cardButtonText {
                fontColor
                fontSize
                fontFamily
                fontWeight
                letterSpacing
                lineHeight
                textTransform
                content {
                  json
                }
              }
              color
              slug
            }
          }
          metadataTitle
          metadataDescription {
            internal {
              content
            }
          }
        }
      }
    }
    header: allContentfulHeader {
      edges {
        node {
          shoppingCartLogo {
            file {
              url
            }
          }
          mainLogo {
            file {
              url
            }
          }
        }
      }
    }
    footer: allContentfulFooter(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          copyright {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          footerMenuItems {
            redirectLink
            titleText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
          }
          socialMediaTitle {
            fontColor
            fontSize
            fontFamily
            fontWeight
            letterSpacing
            lineHeight
            textTransform
            content {
              json
            }
          }
          newsletterEmail {
            buttonText {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            emailLable {
              fontColor
              fontSize
              fontFamily
              fontWeight
              letterSpacing
              lineHeight
              textTransform
              content {
                json
              }
            }
            buttonColor
            emailPlaceholder
          }
          discoverLogo {
            file {
              url
            }
          }
          amexLogo {
            file {
              url
            }
          }
          facebookLogo {
            file {
              url
            }
          }
          instagramLogo {
            file {
              url
            }
          }
          masterCardLogo {
            file {
              url
            }
          }
          payULogo {
            file {
              url
            }
          }
          visaLogo {
            file {
              url
            }
          }
          youTubeLogo {
            file {
              url
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`
